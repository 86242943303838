const state = {
  modalOpen: false,
  modalTitle: "",
  modalContent: false, // is a Vue component
  modalMessage: false, // is a strint
  modalData: false, // is an object
  modalUseCustomFooter: true,
  modalUseCustomHeader: true,
  modalSize: ""
};

const mutations = {
  SET_MODAL_OPEN(state, payload) {
    state.modalOpen = true;
    state.modalTitle = payload.modalTitle;
    state.modalContent = payload.modalContent;
    state.modalMessage = payload.modalMessage;
    state.modalData = payload.modalData;
    state.modalUseCustomFooter = payload.modalUseCustomFooter;
    state.modalUseCustomHeader = payload.modalUseCustomHeader;
    state.modalSize = payload.modalSize;
  },
  SET_MODAL_CLOSED(state) {
    state.modalOpen = false;
    state.modalTitle = "";
    state.modalContent = null;
    state.modalMessage = null;
    state.modalData = false;
    state.modalUseCustomFooter = true;
    state.modalUseCustomHeader = true;
    state.modalSize = "";
  }
};

const getters = {
  modalStatus(state) {
    return state.modalOpen;
  },
  modalTitle(state) {
    return state.modalTitle;
  },
  modalContent(state) {
    return state.modalContent;
  },
  modalMessage(state) {
    return state.modalMessage;
  },
  modalData(state) {
    return state.modalData;
  },
  modalUseCustomFooter(state) {
    return state.modalUseCustomFooter;
  },
  modalUseCustomHeader(state) {
    return state.modalUseCustomHeader;
  },
  modalSize(state) {
    return state.modalSize;
  }
};

const actions = {
  openModal({ commit }, payload) {
    commit("SET_MODAL_OPEN", {
      modalContent: payload.modalContent,
      modalMessage: payload.modalMessage,
      modalData: payload.modalData,
      modalTitle: payload.modalTitle || "",
      modalSize: payload.modalSize,
      modalUseCustomFooter: payload.modalUseCustomFooter || false,
      modalUseCustomHeader: payload.modalUseCustomHeader || false
    });
    document.body.classList.add("modal-open");
  },
  closeModal({ commit }) {
    commit("SET_MODAL_CLOSED");
    document.body.classList.remove("modal-open");
  }
};

export const modal = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
