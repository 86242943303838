import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";
// import VueTheMask from "vue-the-mask";
import "buefy/dist/buefy.css";
import "./scss/main.scss";

import "./registerServiceWorker";
import router from "./router";
import { store } from "./store";

Vue.use(Buefy, {
  defaultIconPack: "fas"
});
// Vue.use(VueTheMask);
Vue.use(require("vue-moment"));
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
