<template>
  <router-link :to="{ name: 'ViewProperty', params: { id: propertyId } }">
    {{ address }}
  </router-link>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["propertyId"],
  methods: {
    ...mapActions({
      getProperty: "property/getItem"
    })
  },
  computed: {
    ...mapState({
      //properties: (state) => state.property.collection,
      property: (state) => state.property.item
    }),
    address: function() {
      if (this.property && this.property.id === this.propertyId) {
        return `${this.property.houseNumber} ${this.property.streetName}`;
      }

      // if (this.properties.length > 0) {
      //   let item = this.properties.find(
      //     (element) => element.id === this.propertyId
      //   );
      //   return `${item.houseNumber} ${item.streetName}`;
      // }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
