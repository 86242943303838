<template>
  <div>
    <section class="section is-title-bar px-0">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Reservation</li>
              <li>Edit</li>
            </ul>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="buttons is-right">
              <a class="is-link" @click="$router.go(-1)">
                Back
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="card">
      <div class="card-content">
        <form @submit.prevent="handleSubmit">
          <div class="field">
            <label for="" class="label">Property</label>
            <div class="control">
              <input
                class="input"
                type="text"
                disabled
                :value="propertyAddress"
              />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label for="" class="label">Arrival Date and Time</label>
                <b-datetimepicker
                  v-model="localReservation.dateArrival"
                  placeholder="Click to select..."
                  icon-pack="fas"
                  icon="calendar"
                  horizontal-time-picker
                  :timepicker="timepicker"
                >
                </b-datetimepicker>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label for="" class="label">Departure Date and Time</label>

                <b-datetimepicker
                  v-model="localReservation.dateDeparture"
                  placeholder="Click to select..."
                  icon-pack="fas"
                  icon="calendar"
                  horizontal-time-picker
                  :timepicker="timepicker"
                >
                </b-datetimepicker>
              </div>
            </div>
          </div>

          <div>
            <button
              class="button is-success is-large is-fullwidth"
              type="submit"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      localReservation: {
        dateArrival: null,
        dateDeparture: null
      },
      timepicker: {
        incrementMinutes: 15
      }
    };
  },
  methods: {
    ...mapActions({
      getProperty: "property/getItem",
      getReservation: "reservation/getItem",
      getProperties: "property/getCollection",
      updateReservation: "reservation/updateItem",
      success: "alert/success",
      activateLoading: "loading/activateLoading",
      deactivateLoading: "loading/deactivateLoading"
    }),
    async handleSubmit() {
      this.activateLoading;
      await this.updateReservation({
        dateArrival: this.localReservation.dateArrival,
        dateDeparture: this.localReservation.dateDeparture,
        id: this.reservation.id
      });
      this.success("Updated reservation.");
      this.$router.push({
        name: "ViewReservation",
        params: {
          id: this.reservation.id
        }
      });
      this.deactivateLoading;
    }
  },
  computed: {
    ...mapState({
      property: (state) => state.property.item,
      reservation: (state) => state.reservation.item
    }),
    propertyAddress() {
      return `${this.property.houseNumber} ${this.property.streetName}`;
    }
  },
  async mounted() {
    await this.getReservation(this.$route.params.id);
    await this.getProperty(this.reservation.propertyId);
    console.log("this.reservation", this.reservation);
    this.localReservation.dateArrival = new Date(
      this.reservation.dateArrivalUnix
    );
    console.log(
      "this.localReservation.dateArrival",
      this.localReservation.dateArrival
    );
    this.localReservation.dateDeparture = new Date(
      this.reservation.dateDepartureUnix
    );
  }
};
</script>

<style lang="scss" scoped></style>
