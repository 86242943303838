<template>
  <div>
    <section class="section is-title-bar px-0">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Visitor</li>
              <li>Edit Visitor</li>
            </ul>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="buttons is-right">
              <a class="is-link" @click="$router.go(-1)">
                Back
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="card" v-if="loaded">
      <div class="card-header">
        <div class="card-header-title">
          <property-address
            v-if="visitor && visitor.propertyId"
            :propertyId="visitor.propertyId"
          />
        </div>
      </div>
      <div class="card-content">
        <form @submit.prevent="handleSubmit">
          <div class="field">
            <label class="label" for="">First Name <span>*</span></label>
            <div class="control">
              <input
                class="input"
                v-model="localVisitor.nameFirst"
                type="text"
                required
              />
            </div>
          </div>
          <div class="field">
            <label class="label" for="">Last Name <span>*</span></label>
            <div class="control">
              <input
                class="input"
                v-model="localVisitor.nameLast"
                type="text"
                required
              />
            </div>
          </div>
          <div class="field" v-if="visitor.notify">
            <label class="label" for="">Send Notification</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="localVisitor.notify">
                  <option
                    v-for="(option, index) in notifyOptions"
                    :value="option.value"
                    :key="index"
                    >{{ option.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label" for=""
              >Email <span v-show="visitor.notify === 'email'">*</span></label
            >
            <div class="control">
              <input
                class="input"
                v-model="localVisitor.email"
                type="email"
                name=""
                id=""
                :required="visitor.notify === 'email'"
              />
            </div>
          </div>
          <div class="field">
            <label class="label" for=""
              >Phone
              <span v-show="localVisitor.notify === 'sms'">*</span></label
            >
            <div class="control">
              <VuePhoneNumberInput
                v-model="phone"
                @update="handlePhone"
                :fetch-country="false"
                :no-use-browser-locale="true"
                :required="localVisitor.notify === 'sms'"
              />
            </div>

            <!-- <div class="control">
              <input
                class="input"
                v-model="localVisitor.phone"
                type="text"
                :required="localVisitor.notify === 'sms'"
              />
            </div>-->
          </div>
          <div class="field">
            <label class="label" for="">Type</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select name="" id="" v-model="localVisitor.type">
                  <option value="once">One Time</option>
                  <option value="reoccurring">Reoccuring</option>
                </select>
              </div>
            </div>
          </div>
          <div v-if="visitor.type === 'reoccurring'">
            <div class="field">
              <label class="label" for=""
                >Allow Access Starting On
                <span v-show="localVisitor.type === 'reoccuring'"
                  >*</span
                ></label
              >
              <b-datetimepicker
                v-model="localVisitor.dateArrival"
                placeholder="Click to select..."
                icon-pack="fas"
                icon="calendar"
                horizontal-time-picker
                :required="localVisitor.type === 'reoccurring'"
                :timepicker="timepicker"
              >
              </b-datetimepicker>
            </div>
            <div class="field">
              <label class="label" for=""
                >End Access On<span v-show="localVisitor.type === 'reoccuring'"
                  >*</span
                ></label
              >
              <b-datetimepicker
                v-model="localVisitor.dateDeparture"
                placeholder="Click to select..."
                icon-pack="fas"
                icon="calendar"
                horizontal-time-picker
                :required="localVisitor.type === 'reoccurring'"
                :timepicker="timepicker"
              >
              </b-datetimepicker>
            </div>
          </div>
          <b-field class="mt-4">
            <b-switch type="is-success" v-model="includePass"
              >Send Pass</b-switch
            >
          </b-field>
          <div>
            <button
              class="button is-success is-large is-fullwidth"
              type="submit"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import propertyAddress from "../../components/property/address.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { mapState, mapActions } from "vuex";
export default {
  components: { propertyAddress, VuePhoneNumberInput },

  data() {
    return {
      phone: "",
      includePass: false,
      loaded: false,
      localVisitor: {
        nameFirst: "",
        nameLast: "",
        email: "",
        phone: "",
        type: "",
        notify: ""
      },
      notifyOptions: [
        { name: "Text/SMS", value: "sms" },
        { name: "Email", value: "email" },
        { name: "None", value: "none" }
      ],
      timepicker: {
        incrementMinutes: 15
      }
    };
  },
  computed: {
    ...mapState({
      property: (state) => state.property.item,
      visitor: (state) => state.visitor.item
    })
  },
  methods: {
    ...mapActions({
      getProperty: "property/getItem",
      getVisitor: "visitor/getItem",
      updateVisitor: "visitor/updateItem",
      success: "alert/success",
      sendPass: "pass/sendPass",
      generatePass: "pass/generatePass",
      activateLoading: "loading/activateLoading",
      deactivateLoading: "loading/deactivateLoading"
    }),
    async handlePhone(newVal) {
      //formattedNumber
      if (newVal.isValid) {
        this.localVisitor.phone = newVal;
      }
    },
    async handleSubmit() {
      this.activateLoading;
      await this.updateVisitor({ id: this.visitor.id, ...this.localVisitor });
      this.success("Updated Visitor");

      if (this.includePass) {
        let pass = await this.generatePass({
          type: "visitors",
          typeId: this.visitor.id,
          propertyId: this.property.id
        });
        await this.sendPass({
          pass: pass,
          passholder: this.localVisitor,
          property: this.property
        });
        this.success("Pass Sent to Visitor");
      }
      this.$router.push({
        name: "ViewProperty",
        params: { id: this.property.id }
      });
      this.deactivateLoading;
    }
  },
  async mounted() {
    this.loaded = false;
    await this.getVisitor(this.$route.params.id);
    await this.getProperty(this.visitor.propertyId);
    for (let variable in this.localVisitor) {
      this.localVisitor[variable] = this.visitor[variable];
    }
    if (this.localVisitor.phone) {
      this.phone = this.localVisitor.phone.formatInternational;
    }
    this.loaded = true;
  }
};
</script>

<style lang="scss" scoped></style>
