var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('section',{staticClass:"section is-title-bar px-0"},[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('ul',[_c('li',[_vm._v("Properties")]),(!_vm.loading)?_c('li',[_vm._v(" "+_vm._s(_vm.property.houseNumber)+" "+_vm._s(_vm.property.streetName)+" ")]):_vm._e()])])])])]),(!_vm.loading)?_c('section',[_c('div',{},[_c('section',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-title"},[_vm._v(" Access ")]),_c('div',{staticClass:"buttons "},[(_vm.property)?_c('router-link',{staticClass:"button is-small",attrs:{"to":{
                name: 'AddVisitor',
                params: { propertyId: _vm.property.id }
              }}},[_vm._v(" Add Visitor ")]):_vm._e(),(_vm.property)?_c('router-link',{staticClass:"button is-small",attrs:{"to":{
                name: 'AddVendor',
                params: { propertyId: _vm.property.id }
              }}},[_vm._v(" Add Vendor ")]):_vm._e(),(_vm.property && _vm.propertyRelationship.role === 'owner')?_c('router-link',{staticClass:"button is-small",attrs:{"to":{
                name: 'AddManager',
                params: { propertyId: _vm.property.id }
              }}},[_vm._v(" Add Manager ")]):_vm._e()],1)]),_c('div',{staticClass:"card-content pt-1"},[_c('b-tabs',{model:{value:(_vm.accessTabs),callback:function ($$v) {_vm.accessTabs=$$v},expression:"accessTabs"}},[_c('b-tab-item',{attrs:{"label":"Visitors"}},[_c('visitors-table',{attrs:{"visitors":_vm.visitors}})],1),_c('b-tab-item',{attrs:{"label":"Vendors"}},[_c('vendors-table',{attrs:{"vendors":_vm.vendors}})],1),(_vm.propertyRelationship.role === 'owner')?_c('b-tab-item',{attrs:{"label":"Managers"}},[_c('managers-table',{attrs:{"managers":_vm.managers}})],1):_vm._e()],1)],1)]),_c('section',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-title"},[_vm._v(" Reservations ")]),_c('router-link',{staticClass:"button is-small",attrs:{"to":{ name: 'AddReservation' }}},[_vm._v(" Add Reservation ")])],1),_c('div',{staticClass:"card-content pt-1"},[_c('b-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{attrs:{"label":"Current"}},[_c('currentReservationsCard',{attrs:{"property":_vm.property}})],1),_c('b-tab-item',{attrs:{"label":"Pending"}},[_c('pendingReservationsCard',{attrs:{"property":_vm.property}})],1),_c('b-tab-item',{attrs:{"label":"Registered"}},[_c('registeredReservationsCard',{attrs:{"property":_vm.property}})],1),_c('b-tab-item',{attrs:{"label":"Canceled"}},[_c('canceledReservationsCard',{attrs:{"property":_vm.property}})],1)],1)],1)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }