<template>
  <div class="container mt-3 pt-6">
    <section class="section">
      <div class="container has-text-centered">
        <div class="columns is-centered">
          <div class="column is-5 is-4-desktop">
            <div class="mb-4">
              <h4 class="subtitle">
                Enter your email address to get a password reset link.
              </h4>
            </div>
            <form @submit.prevent="handleSubmit">
              <div class="field">
                <div class="control">
                  <input
                    class="input is-large"
                    type="email"
                    placeholder="Email"
                    v-model="form.email"
                    required
                  />
                </div>
              </div>
              <div class="field">
                <button
                  class="button is-success is-fullwidth is-large"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ResetPassword",
  data() {
    return {
      form: {
        email: ""
      }
    };
  },
  methods: {
    ...mapActions({
      resetPassword: "user/forgotPassword",
      error: "alert/error"
    }),
    async handleSubmit() {
      try {
        const { email } = this.form;
        await this.resetPassword(email);
        alert("Link sent. Please check your email.");
        this.$router.push({ path: "/" });
      } catch (error) {
        this.error(error.message);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
