import { firebase, config } from "../firebaseConfig";

import { store } from "@/store";

import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/public/Login.vue";
import ResetPassword from "../views/public/ResetPassword.vue";
// import Dashboard from "../views/private/Dashboard.vue";
import Properties from "../views/private/Properties.View.vue";
import AddGuest from "../views/private/Guest.Add.vue";
import EditGuest from "../views/private/Guest.Edit.vue";
import ViewGuest from "../views/private/Guest.View.vue";
import AddReservation from "../views/private/Reservation.Add.vue";
import EditReservation from "../views/private/Reservation.Edit.vue";
import ViewReservation from "../views/private/Reservation.View.vue";
import ViewReservations from "../views/private/Reservations.View.vue";
import AddManager from "../views/private/Manager.Add.vue";
import EditManager from "../views/private/Manager.Edit.vue";
import ViewManager from "../views/private/Manager.View.vue";
import ViewProperty from "../views/private/Property.View.vue";
import AddVendor from "../views/private/Vendor.Add.vue";
import EditVendor from "../views/private/Vendor.Edit.vue";
import ViewVendor from "../views/private/Vendor.View.vue";
import AddVisitor from "../views/private/Visitor.Add.vue";
import EditVisitor from "../views/private/Visitor.Edit.vue";
import ViewVisitor from "../views/private/Visitor.View.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/properties",
    name: "Properties",
    component: Properties,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reservation/add/:propertyId?",
    name: "AddReservation",
    component: AddReservation,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reservation/edit/:id",
    name: "EditReservation",
    component: EditReservation,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reservation/view/:id",
    name: "ViewReservation",
    component: ViewReservation,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reservations/:type",
    name: "ViewReservations",
    component: ViewReservations,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guest/add/:propertyId/:reservationId",
    name: "AddGuest",
    component: AddGuest,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guest/edit/:id",
    name: "EditGuest",
    component: EditGuest,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guest/view/:id",
    name: "ViewGuest",
    component: ViewGuest,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/manager/add/:propertyId",
    name: "AddManager",
    component: AddManager,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/manager/edit/:id",
    name: "EditManager",
    component: EditManager,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/view/:id",
    name: "ViewManager",
    component: ViewManager,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/property/view/:id",
    name: "ViewProperty",
    component: ViewProperty,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/visitor/add/:propertyId",
    name: "AddVisitor",
    component: AddVisitor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/visitor/edit/:id",
    name: "EditVisitor",
    component: EditVisitor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/visitor/view/:id",
    name: "ViewVisitor",
    component: ViewVisitor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vendor/add/:propertyId",
    name: "AddVendor",
    component: AddVendor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vendor/edit/:id",
    name: "EditVendor",
    component: EditVendor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vendor/view/:id",
    name: "ViewVendor",
    component: ViewVendor,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "is-active active",
  linkExactActiveClass: "is-active exact-active",
});

router.beforeEach(async (to, from, next) => {
  const authRequired = to.matched.some((record) => record.meta.requiresAuth);
  const triggerAction = to.meta.triggerAction;
  // const supportAvailable = to.meta.supportAvailable;
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // Default Dynamic Page Title
  // document.title = `${config.companyName} ${config.appName}`;
  // Do we have a Page Title Meta Prop?
  if (nearestWithTitle)
    document.title = `${config.companyName} ${config.appName} - ${nearestWithTitle.meta.title}`;

  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  if (nearestWithMeta) {
    nearestWithMeta.meta.metaTags
      .map((tagDef) => {
        const tag = document.createElement("meta");
        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute("data-vue-router-controlled", "");
        return tag;
      })
      .forEach((tag) => document.head.appendChild(tag));
  }
  // Does the Route Have a meta -> trigger?
  // This fires up the associated STORE actions
  // e.g. "user/getUsername"
  if (triggerAction) {
    store.dispatch(triggerAction, {
      root: true,
    });
  }

  if (authRequired) {
    try {
      await firebase.auth().onAuthStateChanged(async (user) => {
        // try {
        await store.dispatch("user/setLoggedInStatus", true);
        await store.dispatch("user/setUser", user);
        return next();
        // if (!store.state.account.itemSet) {
        //   // await store.dispatch("loading/activateLoading");
        //   await store.dispatch("account/getAccountInfo");
        //   setTimeout(async () => {
        //     // await store.dispatch("loading/deactivateLoading");
        //     return next();
        //   }, 1500);
        // }
        // } catch (error) {
        //   console.log("User is not signed in", error);
        //   return next("/");
        // }
      });
    } catch (error) {
      console.log("User is not signed in", error);
      return next("/");
    }
  } else {
    return next();
  }
});

export default router;
