import { firebase } from "../firebaseConfig";

const state = {
  item: {
    houseNumber: "",
    streetName: ""
  },
  collection: [],
  relationship: null
};

const mutations = {
  SET_PROPERTY_RELATIONSHIP(state, payload) {
    state.relationship = payload.data;
  },
  CLEAR_PROPERTY_RELATIONSHIP(state) {
    state.relationship = null;
  },
  SET_ITEM(state, payload) {
    state.item = payload;
  },
  CLEAR_ITEM(state) {
    state.item = { houseNumber: "", streetName: "" };
  },
  SET_COLLECTION(state, payload) {
    state.collection = payload;
  },
  ADD_ITEM_TO_COLLECTION(state, payload) {
    state.collection.unshift(payload);
  },
  REMOTE_ITEM_FROM_COLLECTION(state, payload) {
    const i = state.collection.map((item) => item.id).indexOf(payload.id);
    state.collection.splice(i, 1);
  },
  CLEAR_COLLECTION(state) {
    state.collection = [];
  }
};

const actions = {
  // eslint-disable-next-line
  async getRelationshipToProperty({ commit }, propertyId) {
    try {
      let relationshipToProperty = await firebase
        .firestore()
        .collection("users")
        .doc(firebase.auth().currentUser.uid)
        .collection("properties")
        .doc(propertyId)
        .get();

      let relationship = {
        id: relationshipToProperty.id,
        data: relationshipToProperty.data()
      };
      commit("SET_PROPERTY_RELATIONSHIP", relationship);
      return Promise.resolve(relationship);
    } catch (error) {
      return Promise.reject();
    }
  },
  async getItem({ commit }, propertyId) {
    try {
      let property = await firebase
        .firestore()
        .collection("properties")
        .doc(propertyId)
        .get();

      commit("SET_ITEM", {
        id: propertyId,
        ...property.data()
      });
      return Promise.resolve();
    } catch (error) {
      console.error("getItem error", error);
      return Promise.reject();
    }
  },
  async getCollection({ commit }) {
    commit("CLEAR_COLLECTION");
    try {
      let propertiesQuery = await firebase
        .firestore()
        .collection("users")
        .doc(firebase.auth().currentUser.uid)
        .collection("properties")
        .get();
      let properties = [];
      for (let index = 0; index < propertiesQuery.docs.length; index++) {
        let property = propertiesQuery.docs[index];

        let propertyQuery = await firebase
          .firestore()
          .collection("properties")
          .doc(property.id)
          .get();

        const docObj = {
          id: property.id,
          // snap: property,
          ...property.data(),
          ...propertyQuery.data()
        };
        commit("ADD_ITEM_TO_COLLECTION", docObj);
        properties.push(docObj);

        if (index + 1 === propertiesQuery.docs.length) {
          return Promise.resolve(properties);
        }
      }
    } catch (error) {
      console.log("error", error);
      return Promise.reject();
    }
  }
};

const getters = {
  getProperties: (state) => {
    return state.collection;
  }
};
export const property = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
