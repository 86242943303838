<template>
  <section class="section">
    <div class="container has-text-centered">
      <div class="columns is-centered">
        <div class="column is-5 is-4-desktop">
          <div class="mb-4">
            <h3 class="title">Welcome!</h3>
            <h4 class="subtitle">Please sign in to continue.</h4>
          </div>
          <form @submit.prevent="handleSubmit">
            <div class="field">
              <div class="control">
                <input
                  class="input is-large"
                  type="email"
                  placeholder="Email"
                  v-model="form.email"
                  required
                />
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  class="input is-large"
                  type="password"
                  placeholder="Password"
                  v-model="form.password"
                  required
                />
              </div>
            </div>
            <div class="field">
              <button
                class="button is-success is-fullwidth is-large"
                type="submit"
              >
                Log In
              </button>
            </div>
          </form>
          <p class="mt-3">
            Please note that access to this system is restricted and all access
            is logged.
          </p>
          <div class="reset-password-link">
            <router-link :to="{ name: 'ResetPassword' }">Forgot your password?</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { version } from "../../../package.json";

export default {
  name: "Login",
  computed: {
    version() {
      return version || 0;
    }
  },
  data() {
    return {
      form: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    ...mapActions({
      signInAction: "user/signInAction",
      authAction: "user/authAction",
      activateLoading: "loading/activateLoading",
      deactivateLoading: "loading/deactivateLoading",
      error: "alert/error"
    }),
    async handleSubmit() {
      try {
        this.activateLoading();
        const { email, password } = this.form;
        await this.signInAction({ email, password });
        this.$router.push({ name: "Properties" });
        this.deactivateLoading();
      } catch (error) {
        this.deactivateLoading();
        this.error(error.message);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.reset-password-link {
  margin-top: 1rem;
}
</style>
