<template>
  <b-table :data="visitors">
    <template #empty>
      <div class="has-text-centered">No visitors found.</div>
    </template>
    <b-table-column field="id" label="Name" v-slot="props">
      {{ props.row.nameFirst }} {{ props.row.nameLast }}
    </b-table-column>
    <b-table-column field="type" label="Type" v-slot="props">
      <span class="is-capitalized"> {{ props.row.type }}</span>
    </b-table-column>
    <b-table-column field="notify" label="Notify By" v-slot="props">
      {{ props.row.notify }}
    </b-table-column>
    <b-table-column field="email" label="Email" v-slot="props">
      <a :href="`mailto:${props.row.email}`">{{ props.row.email }}</a>
    </b-table-column>
    <b-table-column field="phone" label="Phone" v-slot="props">
      <a
        :href="`${props.row.phone.uri}`"
        v-if="props.row.phone && props.row.phone.formatNational"
      >
        <span
          v-if="props.row.phone && props.row.phone.countryCallingCode"
          class="mr-3"
          >+{{ props.row.phone.countryCallingCode }}</span
        >
        <span>{{ props.row.phone.formatNational }}</span>
      </a>
    </b-table-column>
    <b-table-column v-slot="props">
      <b-dropdown aria-role="list" position="is-bottom-left">
        <template #trigger>
          <b-button icon-pack="fas" type="is-outline" rounded size="is-small">
            <i class="fas fa-ellipsis-v"></i>
          </b-button>
        </template>

        <b-dropdown-item
          @click="handleSendPass(props.row)"
          aria-role="listitem"
        >
          Send Pass
        </b-dropdown-item>
        <b-dropdown-item @click="handleEdit(props.row.id)" aria-role="listitem">
          Edit
        </b-dropdown-item>
        <b-dropdown-item
          @click="handleRemoveVisitor(props.row.id)"
          aria-role="listitem"
        >
          Remove
        </b-dropdown-item>
      </b-dropdown>
    </b-table-column>
  </b-table>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["visitors"],
  computed: {
    ...mapState({
      property: (state) => state.property.item,
      reservation: (state) => state.reservation.item
    })
  },
  methods: {
    ...mapActions({
      removeVisitor: "visitor/removeItem",
      sendPass: "pass/sendPass",
      generatePass: "pass/generatePass",
      success: "alert/success"
    }),
    async handleSendPass(visitor) {
      let pass = await this.generatePass({
        type: "visitors",
        typeId: visitor.id,
        propertyId: this.property.id
      });
      await this.sendPass({
        pass: pass,
        passholder: visitor,
        property: this.property
      });
      this.success("Pass Sent to Visitor");
    },
    async handleEdit(id) {
      this.$router.push({
        name: "EditVisitor",
        params: {
          id: id
        }
      });
    },
    async handleRemoveVisitor(id) {
      await this.removeVisitor(id);
      this.success("Removed Visitor");
    }
  }
};
</script>

<style lang="scss" scoped></style>
