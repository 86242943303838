import { firebase, functions } from "../firebaseConfig";

const state = {
  item: null,
  collection: []
};

const mutations = {
  SET_ITEM(state, payload) {
    state.item = payload;
  },
  CLEAR_ITEM(state) {
    state.item = null;
  },
  SET_COLLECTION(state, payload) {
    state.collection = payload;
  },
  ADD_ITEM_TO_COLLECTION(state, payload) {
    state.collection.unshift(payload);
  },
  REMOTE_ITEM_FROM_COLLECTION(state, payload) {
    const i = state.collection.map((item) => item.id).indexOf(payload.id);
    state.collection.splice(i, 1);
  },
  CLEAR_COLLECTION(state) {
    state.collection = [];
  }
};

const actions = {
  // eslint-disable-next-line
  async createItem({}, item) {
    try {
      const { manager, property } = item;
      let userQuery = await firebase
        .firestore()
        .collection("users")
        .where("email", "==", manager.email)
        .get();
      if (userQuery.empty) {
        // create User
        let addManager = functions.httpsCallable("addManager");
        await addManager({ user: manager, property: property });
      } else {
        // adds a role to a user for manager of a property
        userQuery.docs[0]
          .collection("properties")
          .doc(property.id)
          .set({
            role: "manager"
          });
        // adds manager to a property's manager collection
        await firebase
          .firestore()
          .collection("properties")
          .doc(property.id)
          .collection("managers")
          .doc(userQuery.docs[0].id)
          .set();
      }

      // commit("ADD_ITEM_TO_COLLECTION", tempItem);
      // commit("SET_ITEM", tempItem);
      return Promise.resolve();
    } catch (error) {
      console.error("createItem error", error);
      // if (error.code === "auth/email-already-in-use") {

      // }
      return Promise.reject();
    }
  },
  async updateItem({ commit }, payload) {
    const { id, ...everythingElse } = payload;
    try {
      await firebase
        .firestore()
        .collection("users")
        .doc(id)
        .update(everythingElse);
      commit("SET_ITEM", {
        payload
      });
      return Promise.resolve();
    } catch (error) {
      console.error("getItem error", error);
      return Promise.reject();
    }
  },
  async deleteItem({ commit }, item) {
    try {
      await firebase
        .firestore()
        .collection("users")
        .doc(item.managerId)
        .collection("properties")
        .doc(item.propertyId)
        .delete();
      await firebase
        .firestore()
        .collection("properties")
        .doc(item.propertyId)
        .collection("managers")
        .doc(item.managerId)
        .delete();
      commit("REMOTE_ITEM_FROM_COLLECTION", { id: item.managerId });
      return Promise.resolve();
    } catch (error) {
      console.error("createDelete error", error);
      return Promise.reject();
    }
  },
  async getItem({ commit }, managerId) {
    try {
      let managerQuery = await firebase
        .firestore()
        .collection("users")
        .doc(managerId)
        .get();
      commit("SET_ITEM", {
        id: managerQuery.id,
        ...managerQuery.data()
      });
      return Promise.resolve();
    } catch (error) {
      console.error("getItem error", error);
      return Promise.reject();
    }
  },
  async getCollectionByProperty({ commit }, propertyId) {
    commit("CLEAR_COLLECTION");
    try {
      let managerQuery = await firebase
        .firestore()
        .collection("properties")
        .doc(propertyId)
        .collection("managers")
        .get();

      managerQuery.docs.forEach(async (manager) => {
        console.log("forEachmanager", manager);
        let mgr = await firebase
          .firestore()
          .collection("users")
          .doc(manager.id)
          .get();
        let docObj = {
          id: mgr.id,
          ...mgr.data()
        };
        commit("ADD_ITEM_TO_COLLECTION", docObj);
      });
      return Promise.resolve();
    } catch (error) {
      console.error("getItems error", error);
      return Promise.reject();
    }
  },
  async getCollection({ commit }, propertyId) {
    commit("CLEAR_COLLECTION");
    try {
      let managerQuery = await firebase
        .firestore()
        .collection("users")
        .where("propertyId", "==", propertyId)
        .get();

      managerQuery.docs.forEach((manager) => {
        let docObj = {
          id: manager.id,
          ...manager.data()
        };
        commit("ADD_ITEM_TO_COLLECTION", docObj);
      });
      return Promise.resolve();
    } catch (error) {
      console.error("getItems error", error);
      return Promise.reject();
    }
  }
};

const getters = {};
export const manager = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
