<template>
  <!-- Modal -->
  <div
    class="modal"
    tabindex="-1"
    aria-modal="true"
    aria-hidden="true"
    :class="{ show: modalStatus }"
  >
    <div class="modal-dialog" :class="modalSize">
      <div class="modal-content">
        <div class="modal-header" v-if="!modalUseCustomHeader">
          <h5 v-if="modalTitle" class="modal-title">{{ modalTitle }}</h5>
          <button
            type="button"
            @click="handleModalClose"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <component v-if="modalContent" v-bind:is="modalContent"></component>
        <div v-if="modalMessage" class="modal-body" v-html="modalMessage"></div>
        <div class="modal-footer" v-if="!modalUseCustomFooter">
          <button type="button" class="btn btn-dark" @click="handleModalClose">
            Okay
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters({
      modalTitle: "modal/modalTitle",
      modalData: "modal/modalData",
      modalSize: "modal/modalSize",
      modalUseCustomFooter: "modal/modalUseCustomFooter",
      modalUseCustomHeader: "modal/modalUseCustomHeader",
      modalStatus: "modal/modalStatus",
      modalContent: "modal/modalContent",
      modalMessage: "modal/modalMessage"
    })
  },
  methods: {
    ...mapActions({
      closeModal: "modal/closeModal"
    }),
    handleModalClose() {
      this.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
.modal.show {
  display: block;
}
.modal-dialog.full-screen {
  max-width: 100%;
  max-height: 100%;

  margin: 0 auto;
  .modal-content {
    border-radius: 0;
    max-height: 100%;
    // height: 100vh;
  }
}
</style>
<style lang="scss">
.modal-dialog.full-screen .modal-content .modal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: none;
}
</style>
