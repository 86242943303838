<template>
  <div>
    <section class="section is-title-bar px-0">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Guest</li>
              <li>Edit</li>
            </ul>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="buttons is-right">
              <a class="is-link" @click="$router.go(-1)">
                Back
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="card">
      <div class="card-header" v-if="guest.property">
        <div class="card-header-title">
          Reservation from: {{ guest.property.houseNumber }}
          {{ guest.property.streetName }}
          {{ guest.reservation.dateArrivalUnix | moment("ddd, MMM Do YYYY") }}
          to
          {{ guest.reservation.dateDepartureUnix | moment("ddd, MMM Do YYYY") }}
        </div>
      </div>
      <div class="card-content">
        <form @submit.prevent="handleSubmit">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">First Name</label>
                <div class="control">
                  <input
                    class="input"
                    v-model="localGuest.nameFirst"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Last Name</label>
                <div class="control">
                  <input
                    class="input"
                    v-model="localGuest.nameLast"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Email</label
            ><input
              class="input"
              v-model="localGuest.email"
              type="email"
              name=""
              id=""
            />
          </div>
          <div class="field">
            <label class="label">Phone</label>
            <div class="control">
              <VuePhoneNumberInput
                v-model="phone"
                @update="handlePhone"
                :fetch-country="false"
                :no-use-browser-locale="true"
                :required="localGuest.notify === 'sms'"
              />
              <!-- <input class="input" v-model="localGuest.phone" type="text" /> -->
            </div>
          </div>
          <div class="field">
            <label class="label">Send Notification</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select name="" id="" v-model="localGuest.notify">
                  <option value="no">No</option>
                  <option value="email">By Email</option>
                  <option value="sms">By Text/SMS</option>
                </select>
              </div>
            </div>
          </div>
          <b-field v-if="!reservation.primarylocalGuest" class="mt-4">
            <b-checkbox v-model="makePrimary" size="is-medium"
              >Primary Guest</b-checkbox
            >
          </b-field>

          <div>
            <button
              class="button is-large is-success is-fullwidth"
              type="submit"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { mapState, mapActions } from "vuex";
export default {
  components: { VuePhoneNumberInput },

  data() {
    return {
      phone: "",

      makePrimary: false,
      localGuest: {
        nameFirst: "",
        nameLast: "",
        email: "",
        phone: "",
        notify: ""
      }
    };
  },
  computed: {
    ...mapState({
      guest: (state) => state.guest.item,
      property: (state) => state.property.item,
      reservation: (state) => state.reservation.item
    })
  },
  methods: {
    ...mapActions({
      getGuest: "guest/getItem",
      getProperty: "property/getItem",
      getReservation: "reservation/getItem",
      updateGuest: "guest/updateItem",
      activateLoading: "loading/activateLoading",
      deactivateLoading: "loading/deactivateLoading",
      success: "alert/success"
    }),
    async handlePhone(newVal) {
      //formattedNumber
      if (newVal.isValid) {
        this.localGuest.phone = newVal;
      }
    },
    async handleSubmit() {
      this.activateLoading;
      await this.updateGuest({
        reservation: { id: this.reservation.id, makePrimary: this.makePrimary },
        guest: {
          ...this.localGuest
        },
        guestId: this.guest.id
      });
      this.success("Updated Guest to Reservation");
      this.$router.push({
        name: "ViewReservation",
        params: { id: this.reservation.id }
      });
      this.activateLoading;
    }
  },
  async mounted() {
    let guestId = this.$route.params.id;
    // alert(guestId);
    await this.getGuest(guestId);

    if (this.guest) {
      for (let variable in this.localGuest) {
        this.localGuest[variable] = this.guest[variable];
      }
      if (
        this.guest.reservation.primaryGuest &&
        this.guest.reservation.primaryGuest === this.guest.id
      ) {
        this.makePrimary = true;
      }
      if (this.localGuest.phone) {
        this.phone = this.localGuest.phone.formatInternational;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
